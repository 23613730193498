import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'

const ColPadding = styled.div`
  max-width: 55%;
  margin: 20px auto;
  @media (max-width: 1700px) {
    max-width: 65%;
  }
  @media (max-width: 1200px) {
    max-width: 80%;
  }
  @media (max-width: 960px) {
    max-width: 90%;
  }
`
const Text = styled.p`
  font-family: 'Muli', Arial, Helvetica, sans-serif;
  font-size: 27px;
  line-height: 40px;
  @media (max-width: 1400px) {
    font-size: 25px;
    line-height: 32px;
  }
  @media (max-width: 960px) {
    font-size: 20px;
    line-height: 28px;
  }
`
const TalkToUsWrapper = styled.div`
  padding: 50px 50px 70px 50px;
  margin: 50px 0 0 0;
  background: rgba(0, 0, 0, 0.1);
  h3 {
    font-family: 'Muli', Arial, Helvetica, sans-serif;
    font-size: 35px;
    margin: 0 0 30px 0;
    padding: 0;
    @media (max-width: 768px) {
      font-size: 30px;
    }
  }
`
const Button = styled.div`
  display: inline-block;
  a {
    display: inline-block;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    background: rgba(0, 0, 0, 0.1);
    border: 2px solid rgba(255, 255, 255, 1);
    padding: 20px 50px;
    color: #fff;
    font-family: 'Muli', Arial, Helvetica, sans-serif;
    font-size: 25px;
    text-transform: uppercase;
    text-decoration: none;
    &:hover {
      background: #fff;
      color: #0f805f;
    }
  }
  @media (max-width: 768px) {
    a {
      font-size: 20px;
    }
  }
`

const IndexPage = () => (
  <Layout>
    <ColPadding>
      <Text>
        CrewTracker was designed from the need to track all aspects of your
        employees in and around your production. CrewTracker provides you with
        the ability to add your crew members, manage their individual profiles
        including their wages, print an employee badge which allows you to clock
        them in and out as they work on your production. At the end you’ll
        receive a detailed report with all the hours worked by each crew member
        with full accountability throughout the process.
      </Text>
    </ColPadding>
    <TalkToUsWrapper>
      <h3>Want to find out more?</h3>
      <Button>
        <Link to="/talk-to-us/">Talk to Us</Link>
      </Button>
    </TalkToUsWrapper>
  </Layout>
)

export default IndexPage
